<template>
  <div>
    <div v-for="(item, i) in exictedUnpredictedGeo" :key="i" class="confirm-edit-smart-excel" v-if="item.display">
      <p>
        الصف رقم {{ item.row }} يوجد به مشكلة - هل تقصد ( منطقة {{ item.references[0].location_y.area }} بدلا من
        <strong>{{ item.reference }}</strong>
        ) ؟
      </p>
      <div class="buttons">
        <svg
          v-if="!loadingConfirm(i)"
          @click="confirmSuggestArea(item, i), index = i"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            stroke="#31006F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <SpinnerColor2Vue v-else />
        <svg
          @click="removeSuggestArea(item, i)"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            stroke="#E80505"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import notifyState from "../../mixins/notifyState";
import SpinnerColor2Vue from '../components/table-components/SpinnerColor2.vue';

export default {
  name: "ConfirmEditSmartExcel",
  mixins: [notifyState],
  components: { SpinnerColor2Vue },
  data() {
    return {
      exictedUnpredictedGeo: [],
      loading: false,
      index: null
    };
  },
  computed: {
    ...mapGetters('dataList', ["unpredicted_geos"]),
    ...mapState('dataList', ["success_api_confirm_suggest_area"]),
  },
  methods: {
    ...mapActions('dataList', ["confirmSuggestAreaInSmartExcel"]),
    loadingConfirm(i) {
      if(i == this.index && this.loading) return true
      else return false
    },
    removeSuggestArea(item, index) {
      this.exictedUnpredictedGeo.splice(index, 1);
      if(!this.exictedUnpredictedGeo.length) this.$emit("fileUploaded")
    },
    async confirmSuggestArea(item, index) {
      this.loading = true;
      let obj = {
        reference: item.reference,
        area: item.references[0].location_y.area,
        government: item.references[0].location_y.government
      }
      await this.confirmSuggestAreaInSmartExcel({data: [obj]})
      if (this.success_api_confirm_suggest_area == 1) {
        this.removeSuggestArea(item, index)
        this.notifySuccess("editToast");
      } else {
        this.notifyFaild("faildEdit");
      }
      this.loading = false;
    }
  },
  created() {
    this.exictedUnpredictedGeo = this.unpredicted_geos.slice()
  },
};
</script>

<style scoped lang="scss">
.confirm-edit-smart-excel {
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  &:last-child {
    margin-bottom: unset;
  }
  p {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    strong {
      color: #e80505;
    }
  }
  .buttons {
    display: flex;
    gap: 8px;
    cursor: pointer;
  }
}
</style>
