<template>
    <div>
        <v-tour :options="tourOptions" name="addOrderExcel" :steps="steps"></v-tour>
        <div class="title-page flex justify-between text-center">
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="18.409" height="18.073" viewBox="0 0 18.409 18.073">
                    <path id="Icon_simple-microsoftexcel" data-name="Icon simple-microsoftexcel" d="M18.066,2.54H11.9V3.681h1.818V5.487H11.9v.575h1.818V7.87H11.9v.591h1.818v1.71H11.9v.685h1.818V12.57H11.9v.685h1.818v1.724H11.9v1.257h6.167c.1-.029.179-.144.244-.344a1.682,1.682,0,0,0,.1-.488V2.748c0-.1-.034-.157-.1-.178A.844.844,0,0,0,18.066,2.54Zm-.8,12.437H14.307V13.255h2.958v1.722Zm0-2.407H14.307V10.855h2.958Zm0-2.4H14.307v-1.7h2.958v1.7Zm0-2.3H14.307V6.064h2.958V7.87h0Zm0-2.392H14.307V3.682h2.958V5.477ZM0,2.255V16.523L10.861,18.4V.329L0,2.261ZM6.438,13.048q-.062-.168-.581-1.429c-.344-.841-.552-1.33-.613-1.47H5.224L4.059,12.922,2.5,12.817,4.349,9.366,2.658,5.914,4.246,5.83l1.049,2.7h.021L6.5,5.708l1.641-.1L6.188,9.34,8.2,13.15l-1.763-.1Z" transform="translate(0 -0.329)" fill="#6e6b7b"/>
                </svg>
                <h3 class="font-bold mb-2 ml-3" style="display: inline-block; font-size: 1.125rem;">{{$t('donwloadOrderExcel')}}</h3>
            </div>
        </div>
        <div class="line"></div>
        <div class="content" style="border-radius: 4px;" data-order-excel-tour-step="1">
            <p style="color: #000000; font-size: 1rem;" class="font-bold">{{$t('followSteps')}}</p>
            <p class="content-btn flex items-center mt-3">
                {{$t('downloadDemo')}}
                <a :href="typeExcel == 1 ? urlExcel+  `assets/admin/media/Add Orders.xlsx` : urlExcel+ `assets/admin/media/Add-Orders-Excel-(ai-New).xlsx`" class="upload-btn ml-3" download>
                    <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <g id="Icon_feather-download" data-name="Icon feather-download" transform="translate(-3.5 -3.5)">
                            <path id="Path_1608" data-name="Path 1608" d="M18.5,22.5v3.111a1.556,1.556,0,0,1-1.556,1.556H6.056A1.556,1.556,0,0,1,4.5,25.611V22.5" transform="translate(0 -8.667)" fill="none" stroke="#28c76f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            <path id="Path_1609" data-name="Path 1609" d="M10.5,15l3.889,3.889L18.278,15" transform="translate(-2.889 -5.056)" fill="none" stroke="#28c76f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            <path id="Path_1610" data-name="Path 1610" d="M18,13.833V4.5" transform="translate(-6.5)" fill="none" stroke="#28c76f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </g>
                    </svg>
                    {{$t('downloadFile')}}
                </a>
            </p>
            <p class="content-btn mt-3 mb-5">{{$t('afterFillingData')}}</p>
        </div>
        <div class="body-excel" style="border-radius: 4px;" data-order-excel-tour-step="2">
            <div class="excel" :style="[checkSuccess && upload == true ? {'background-color': '#28C76F0D'} : {} , succsess == 0 && upload == true ? {'border': 'dashed 2px #D81212'} : {'border': 'dashed 2px #28C76F'} ]">
                <div  v-if="!loading" style="position: relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="73.803" height="91.004" viewBox="0 0 73.803 91.004">
                        <g id="Icon_feather-file-plus" data-name="Icon feather-file-plus" transform="translate(-3.5 -0.5)">
                            <path id="Path_1603" data-name="Path 1603" d="M49,3H14.6A8.6,8.6,0,0,0,6,11.6V80.4A8.6,8.6,0,0,0,14.6,89H66.2a8.6,8.6,0,0,0,8.6-8.6V28.8Z" transform="translate(0 0)" fill="none" stroke="#28c76f" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                            <path id="Path_1604" data-name="Path 1604" d="M21,3V28.8H46.8" transform="translate(28.002 0)" fill="none" stroke="#28c76f" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                            <path id="Path_1605" data-name="Path 1605" d="M18,43.8V18" transform="translate(22.401 28.002)" fill="none" stroke="#28c76f" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                            <path id="Path_1606" data-name="Path 1606" d="M13.5,22.5H39.3" transform="translate(14.001 36.402)" fill="none" stroke="#28c76f" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                        </g>
                    </svg>
                    <p class="text">{{$t('uploadFileAfter')}}</p>
                    <p class="text2" v-if="fileExcel == ''">{{$t('formulaAllowed')}}</p>
                    <p class="text2" v-else>{{ fileExcel }}</p>
                    <input title=""  @change="handleClick" ref="fileInput" accept=".xlsx, .xls" type="file" class="upload-excel-file" />
                    <button class="btn-excel">{{$t('chooseFile')}}</button>
                    <!-- <button class="btn-excel" @click="upload = true, handleClick(), loading = true" v-else>{{$t('uploadFile')}}</button> -->
                </div>
                <div v-else-if="loading && !upload">
                    <span style="position: relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="73.803" height="91.004" viewBox="0 0 73.803 91.004">
                            <g id="Icon_feather-file-plus" data-name="Icon feather-file-plus" transform="translate(-3.5 -0.5)">
                                <path id="Path_1603" data-name="Path 1603" d="M49,3H14.6A8.6,8.6,0,0,0,6,11.6V80.4A8.6,8.6,0,0,0,14.6,89H66.2a8.6,8.6,0,0,0,8.6-8.6V28.8Z" transform="translate(0 0)" fill="none" stroke="#28c76f" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                                <path id="Path_1604" data-name="Path 1604" d="M21,3V28.8H46.8" transform="translate(28.002 0)" fill="none" stroke="#28c76f" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                            </g>
                        </svg>
                        <svg class="spin-loading" xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" style="position: absolute; top: -2.0625rem; right: 1.5625rem;">
                            <g id="Icon_feather-loader" data-name="Icon feather-loader" transform="translate(-1.5 -1.5)">
                                <path id="Path_1611" data-name="Path 1611" d="M18,3V9" fill="none" stroke="#28c76f" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Path_1612" data-name="Path 1612" d="M18,27v6" fill="none" stroke="rgba(40,199,111,0.75)" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Path_1613" data-name="Path 1613" d="M7.395,7.395,11.64,11.64" fill="none" stroke="rgba(40,199,111,0.1)" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Path_1614" data-name="Path 1614" d="M24.36,24.36,28.6,28.6" fill="none" stroke="#28c76f" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Path_1615" data-name="Path 1615" d="M3,18H9" fill="none" stroke="rgba(40,199,111,0.25)" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Path_1616" data-name="Path 1616" d="M27,18h6" fill="none" stroke="#28c76f" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Path_1617" data-name="Path 1617" d="M7.395,28.6,11.64,24.36" fill="none" stroke="rgba(40,199,111,0.5)" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Path_1618" data-name="Path 1618" d="M24.36,11.64,28.6,7.395" fill="none" stroke="#28c76f" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </span>
                    <p class="text">{{$t('pleaseWait')}}</p>
                    <p class="text2">{{$t('uploadingYourFile')}}</p>
                    <div class="parent-loading">
                        <div class="child-loading"></div>
                    </div>
                </div>
                <div v-if="checkSuccess && upload == true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="73.803" height="91.004" viewBox="0 0 73.803 91.004">
                        <g id="Group_1465" data-name="Group 1465" transform="translate(-683.5 -426.597)">
                            <g id="Icon_feather-file-plus" data-name="Icon feather-file-plus" transform="translate(680 426.097)">
                            <path id="Path_1603" data-name="Path 1603" d="M49,3H14.6A8.6,8.6,0,0,0,6,11.6V80.4A8.6,8.6,0,0,0,14.6,89H66.2a8.6,8.6,0,0,0,8.6-8.6V28.8Z" transform="translate(0 0)" fill="none" stroke="#28c76f" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                            <path id="Path_1604" data-name="Path 1604" d="M21,3V28.8H46.8" transform="translate(28.002 0)" fill="none" stroke="#28c76f" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                            </g>
                            <path id="Icon_material-done" data-name="Icon material-done" d="M13.5,24.3,7.2,18,5.1,20.1l8.4,8.4,18-18L29.4,8.4Z" transform="translate(701.9 465.648)" fill="#28c76f"/>
                        </g>
                    </svg>
                    <p class="text">{{$t('fileHasBeenUploaded')}}</p>
                    <p class="text2">{{success_msg || $t('ordersHassBeenUploaded')}}</p>
                    <p style="text-align: center" >
                        <span @click="uploadFileAgain()" style="color: #6E6B7B; font-size: 0.75rem; font-weight: bold; font-family: cairo; cursor: pointer;">
                            <svg class="mr-1" style="transform: translateY(0.1625rem);" xmlns="http://www.w3.org/2000/svg" width="12.02" height="12.02" viewBox="0 0 12.02 12.02">
                                <g id="Icon_ionic-ios-close-circle-outline" data-name="Icon ionic-ios-close-circle-outline" transform="translate(-3.375 -3.375)">
                                    <path id="Path_1624" data-name="Path 1624" d="M16.479,15.877l-1.4-1.4,1.4-1.4a.423.423,0,1,0-.6-.6l-1.4,1.4-1.4-1.4a.423.423,0,0,0-.6.6l1.4,1.4-1.4,1.4a.409.409,0,0,0,0,.6.42.42,0,0,0,.6,0l1.4-1.4,1.4,1.4a.425.425,0,0,0,.6,0A.421.421,0,0,0,16.479,15.877Z" transform="translate(-5.1 -5.097)" fill="#6e6b7b"/>
                                    <path id="Path_1625" data-name="Path 1625" d="M9.385,4.184A5.2,5.2,0,1,1,5.707,5.707,5.167,5.167,0,0,1,9.385,4.184m0-.809a6.01,6.01,0,1,0,6.01,6.01,6.009,6.009,0,0,0-6.01-6.01Z" transform="translate(0 0)" fill="#6e6b7b"/>
                                </g>
                            </svg>
                            {{ fileExcel }}
                        </span>
                    </p>
                    <div class="btn-done">
                        <router-link class="order-btn" to="/SavedOrder">{{$t('preservedOrder')}}</router-link>
                        <router-link class="main-btn" to="/">{{$t('home')}}</router-link>
                    </div>
                </div>
                <div v-else-if="!checkSuccess && upload == true">
                    <span style="position: relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="73.803" height="91.004" viewBox="0 0 73.803 91.004">
                            <g id="Icon_feather-file-plus" data-name="Icon feather-file-plus" transform="translate(-3.5 -0.5)">
                                <path id="Path_1603" data-name="Path 1603" d="M49,3H14.6A8.6,8.6,0,0,0,6,11.6V80.4A8.6,8.6,0,0,0,14.6,89H66.2a8.6,8.6,0,0,0,8.6-8.6V28.8Z" transform="translate(0 0)" fill="none" stroke="#d81212" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                                <path id="Path_1604" data-name="Path 1604" d="M21,3V28.8H46.8" transform="translate(28.002 0)" fill="none" stroke="#d81212" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                            </g>
                        </svg>

                        <svg class="mr-1" style="position: absolute; top: -2.0625rem; right: 1.3125rem;" xmlns="http://www.w3.org/2000/svg" width="31.482" height="27.603" viewBox="0 0 31.482 27.603">
                            <g id="Icon_feather-alert-triangle" data-name="Icon feather-alert-triangle" transform="translate(-0.788 -2.846)">
                                <path id="Path_1619" data-name="Path 1619" d="M14.2,5.654,2.692,24.872a2.718,2.718,0,0,0,2.324,4.077H28.039a2.718,2.718,0,0,0,2.324-4.077L18.852,5.654a2.718,2.718,0,0,0-4.648,0Z" fill="none" stroke="#d81212" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Path_1620" data-name="Path 1620" d="M18,13.5v5.436" transform="translate(-1.472 -0.86)" fill="none" stroke="#d81212" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Path_1621" data-name="Path 1621" d="M18,25.5h0" transform="translate(-1.472 -1.987)" fill="none" stroke="#d81212" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </span>
                    <p class="text">{{$t('unfortunatelyFile')}}</p>
                    <div v-if="err_msg == 'old'" class="text2 error-excel">
                        <svg class="mr-1 mt-2" style="transform: translateY(0.15rem);" xmlns="http://www.w3.org/2000/svg" width="12.02" height="12.02" viewBox="0 0 12.02 12.02">
                            <g id="Icon_ionic-ios-close-circle-outline" data-name="Icon ionic-ios-close-circle-outline" transform="translate(-3.375 -3.375)">
                                <path id="Path_1624" data-name="Path 1624" d="M16.479,15.877l-1.4-1.4,1.4-1.4a.423.423,0,1,0-.6-.6l-1.4,1.4-1.4-1.4a.423.423,0,0,0-.6.6l1.4,1.4-1.4,1.4a.409.409,0,0,0,0,.6.42.42,0,0,0,.6,0l1.4-1.4,1.4,1.4a.425.425,0,0,0,.6,0A.421.421,0,0,0,16.479,15.877Z" transform="translate(-5.1 -5.097)" fill="#d81212"/>
                                <path id="Path_1625" data-name="Path 1625" d="M9.385,4.184A5.2,5.2,0,1,1,5.707,5.707,5.167,5.167,0,0,1,9.385,4.184m0-.809a6.01,6.01,0,1,0,6.01,6.01,6.009,6.009,0,0,0-6.01-6.01Z" transform="translate(0 0)" fill="#d81212"/>
                            </g>
                        </svg> نسخة الملف الذي تحاول رفعه قديمة ، يرجى اتباع الخطوات الاتيه :
                        <p class="inner-error-excel">١- حمل نسخة الملف الجديدة من <a :href="urlExcel+`assets/admin/media/Add Orders.xlsx`"><strong><u>هنا</u></strong></a>
                            ٢- قم بملئ البيانات في النسخة الجديدة من الملف
                            ٣-حاول رفع الملف مرة اخرى
                        </p>
                    </div>
                    <p v-else class="text2">
                        <svg class="mr-1 mt-2" style="transform: translateY(0.15rem);" xmlns="http://www.w3.org/2000/svg" width="12.02" height="12.02" viewBox="0 0 12.02 12.02">
                            <g id="Icon_ionic-ios-close-circle-outline" data-name="Icon ionic-ios-close-circle-outline" transform="translate(-3.375 -3.375)">
                                <path id="Path_1624" data-name="Path 1624" d="M16.479,15.877l-1.4-1.4,1.4-1.4a.423.423,0,1,0-.6-.6l-1.4,1.4-1.4-1.4a.423.423,0,0,0-.6.6l1.4,1.4-1.4,1.4a.409.409,0,0,0,0,.6.42.42,0,0,0,.6,0l1.4-1.4,1.4,1.4a.425.425,0,0,0,.6,0A.421.421,0,0,0,16.479,15.877Z" transform="translate(-5.1 -5.097)" fill="#d81212"/>
                                <path id="Path_1625" data-name="Path 1625" d="M9.385,4.184A5.2,5.2,0,1,1,5.707,5.707,5.167,5.167,0,0,1,9.385,4.184m0-.809a6.01,6.01,0,1,0,6.01,6.01,6.009,6.009,0,0,0-6.01-6.01Z" transform="translate(0 0)" fill="#d81212"/>
                            </g>
                        </svg>
                        {{ err_msg }}
                    </p>
                    <p style="text-align: center" >
                        <span style="color: #6E6B7B; font-size: 0.75rem; font-weight: bold; font-family: cairo;">
                            {{ fileExcel }}
                        </span>
                    </p>
                    <button class="btn-excel" @click="uploadFileAgain()">{{$t('uploadFile')}}</button>
                    <!-- <p class="text-center flex items-center justify-center font-semibold mt-4">
                        <feather-icon class="mr-2" icon="HelpCircleIcon" svgClasses="h-6 w-6"></feather-icon>
                        لو حاولت و لسة بتقابلك نفس المشكلة يمكنك التواصل معنا من 
                        <strong @click="addNewTicket()" :class="loadingTicket ? 'loading-add-ticket' : '' " class="ml-1 cursor-pointer text-primary">
                            <u v-if="!loadingTicket">هنا</u>
                            <SpinnerColor2Vue v-else />
                        </strong>
                    </p> -->
                </div>
            </div>
            <ConfirmEditSmartExcel v-if="unpredicted_geos.length && !checkSuccess && upload == true" @fileUploaded="fileUploaded()" />
        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { mapActions, mapGetters, mapState } from "vuex"
import tour from "@/mixins/tour.js"
import SpinnerColor2Vue from '../components/table-components/SpinnerColor2.vue';
import ConfirmEditSmartExcel from './ConfirmEditSmartExcel.vue'

export default {
    mixins: [tour],
    props: {
        // To Know If Client Will Upload Smart Excel Or Default Not
        typeExcel: {
            required: true,
            type: Number,
        },
    },
    components: {
        Datepicker,
        ConfirmEditSmartExcel,
        SpinnerColor2Vue,
    },
    data () {
        return {
            fileExcel: '',
            succsess: 0,
            upload: false,
            loading: false,
            url: process.env.VUE_APP_FOLDER_PATH,
            urlExcel: process.env.VUE_APP_BASE_URL_EXCEL,
            steps: [
                {
                    target: '[data-order-excel-tour-step="1"]',  // We're using document.querySelector() under the hood
                    header: {
                        title: 'تحميل نسخة توضيحية',
                    },
                    content: `من هنا يمكنك تحميل نسخة توضيح لإكسل الطرود الذي يجب عليك إستخدامة`
                },
                {
                    target: '[data-order-excel-tour-step="2"]',  // We're using document.querySelector() under the hood
                    header: {
                        title: 'إضافة طرود بالإكسل',
                    },
                    content: `من هنا يمكنك اضافة طرود دفعة واحدة عن طريق الإكسيل`
                },
            ],
            loadingTicket: false,
        }
    },
    computed: {
        ...mapGetters('dataList', ["success_api", "err_msg", "success_msg", "unpredicted_geos"]),
        checkSuccess(){
            if(this.succsess == 1){
                return true
            }else{
                return false
            }
        }
    },
    methods: {
        ...mapActions('dataList', ["postOrderExcel", "postOrderSmartExcel"]),
        ...mapActions("ticket", [
            "inquiriesTypes",
            "setTicketPageIndex",
            "setTypeProblem",
            "toggleShowTicket",
            "setTypeTicket",
            "setInquiryTypeId",
            "setSubTitle",
        ]),
        async handleClick (e) {
            this.loading = true
            const files = e.target.files
            const rawFile = files[0]
            this.fileExcel = rawFile.name
            await this.postExcel(rawFile)
            this.upload = true
            this.succsess = this.success_api
        },
        async postExcel(rawFile) {
            if(this.typeExcel == 1) {
                await this.postOrderExcel(rawFile)
            } else {
                await this.postOrderSmartExcel(rawFile)
            }
        },
        fileUploaded(){
            this.loading = true
            this.upload = true
            this.succsess = 1
        },
        uploadFileAgain() {
            this.fileExcel = '',
            this.succsess = 0,
            this.upload = false,
            this.loading = false
        },
        async addNewTicket() {
            this.loadingTicket = true;
            await this.inquiriesTypes();
            this.loadingTicket = false;
            this.toggleShowTicket();
            this.setTicketPageIndex(3);
            this.setTypeProblem(2);
            this.setTypeTicket(1);
            this.setInquiryTypeId(4);
            this.setSubTitle("أخري")
        },
    },
    mounted () {
        if(this.runWalkthrough()) {
            // this.startTour('addOrderExcel')
        }
    }
}
</script>

<style lang="scss">
.inputs{
    display: flex;
    justify-content: space-between;
    width: 53.4375rem;
    margin: auto;
    margin-bottom: 1.5625rem;
    .vdp-datepicker{
        div{
            input{
                width: 25.9375rem;
                border: 0.0625rem solid #D8D6DE;
                border-radius: 0.3125rem;
                height: 2.4375rem;
                padding: 0 0.75rem;
            }
            input::placeholder{
                color: #6E6B7B;
                opacity: 50%;
                font-size: 0.75rem;
                line-height: 1.3125rem;
                font-family: 'cairo';
            }
        }
    }
    .input{
        position: relative;
        i{
            position: absolute;
            right: 0.75rem;
            top: 2.375rem;
            opacity: 85%;
        }
    }

    
}

@media (min-width: 768px) and (max-width: 1094px){
    .inputs{
        display: block !important;
        width: 100% !important;
        .vdp-datepicker{
            div{
                input{
                    width: 100% !important;
                    margin-bottom: 1.125rem;
                }
            }
        }
    }
    .btn-excel{
        width: 25% !important;
    }
    .body-excel{
        .excel{
            width: 100% !important;
        }
    }
}

@media (min-width: 300px) and (max-width: 767px){
    .title-page{
        h3{
            font-size: 0.75rem !important;
        }
        .show-orders{
            font-size: 0.75rem !important;
            width: 8.125rem !important;
        }
    }
    .inputs{
        display: block !important;
        width: 100% !important;
        .vdp-datepicker{
            div{
                input{
                    width: 100% !important;
                    margin-bottom: 1.125rem;
                }
            }
        }
    }
    .btn-excel{
        width: 25% !important;
    }
    .content-btn{
        font-size: 12px;
    }
    .body-excel{
        .text{
            padding: 0 0.9375rem !important;
            font-size: 0.875rem !important;
            margin-bottom: 0.125rem;
        }
        .excel{
            width: 100% !important;
        }
    }
}

</style>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_addOrderWithExcel.scss';
</style>
